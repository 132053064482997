<template>
    <div class="index" >
        <div class="header1">
            <div class="header-l">
                <!-- <img src="../../assets/image/logo.png" alt> -->
                         <img src="../../assets/icon/ad.png" alt="" @click="$router.push('/')">
        <img src="../../assets/icon/shuxian1.png" alt="" style="margin:0 20px">
        <img src="../../assets/icon/xiehui.png" alt="">
            </div>
            <!-- <div class="header-c">
                <p class="p1">中国广告协会战略支持平台</p>
                <p>中国广告协会战略制作平台</p>
            </div> -->
            <div class="header-r">
                <span class="header-r1" @click="$router.push('/login')">验证码登录></span>
            </div>
        </div>
        <!--  wq 以上是头部    -->
        <div class="line"></div>
        <div class="main flex-y">
            <span class="div1">忘记密码</span>
            <div class="div2 flex align-center">
                <input type="text" v-model="phone" placeholder="手机号" maxlength="11">
                <div class="tishi">
                    <div class="tishi-l">*</div>
                    <div class="tishi-r">11位手机号码</div>
                </div>
            </div>

            <div class="div3 flex align-center" v-if="flag">
                <div class="div3-1" @click="verification">点击此处进行验证</div>
                <div class="div3-2">
                    <div class="div3-2-l">*</div>
                    <div class="div3-2-r">点击进行验证</div>
                </div>
            </div>

            <div class="div3-y flex align-center" v-if="flag1">
                <input type="text" placeholder="验证码" class="teshu" v-model="code">
                <div class="time" v-text="seconds"></div>
            </div>

            <div class="div2 mima flex align-center">
                <input type="password" placeholder="设置新密码" v-model="password">
                <div class="tishi">
                    <div class="tishi-l">*</div>
                    <div class="tishi-r">密码长度8-16位，数字、字母、字符至少包含两种</div>
                </div>
            </div>

            <div class="div2 mima mima2 flex align-center">
                <input type="password" placeholder="确认新密码" v-model="confirmPwd">
                <div class="tishi">
                    <div class="tishi-l">*</div>
                    <div class="tishi-r">再次输入密码，确保两次一直</div>
                </div>
            </div>

            <div class="div4">
                <input type="checkbox" class="ipt1" id="checkbox" :checked="agree" @change="agreeHandler">
                <label for="checkbox" class="lab1"></label>
                <div class="div4-2">
                    <span class="span1">我已阅读并同意</span>
                    <span class="span2">《孔雀有礼使用协议》</span>
                </div>
            </div>
            <button class="regist" :class="[agree ? '': 'grey']" @click="updatepaw">修改密码</button>
        </div>
    </div>
</template>

<script>
import { location } from '../../assets/js/LocationUtil';
    export default {
        name: 'index',
        components: {},
        data() {
            return {
                phone: '',
                code: '',
                password: '',
                confirmPwd: '',
                flag: true,
                flag1: false,
                seconds: -1,
                agree: false,
                flagPhone: false, //手机号错误提示显示隐藏
            }
        },
        created(){
            // alert(4544)
            // this.$router.go(0)
        },
        mounted() {
            function init() {
                //挂载钩子函数
                // this.$route.go(0);
            }
            // window.location.reload();
        },
        methods: {
            agreeHandler() {
                this.agree = !this.agree;
            },
            //事件处理对象
            verification(){
                let captcha1 = new TencentCaptcha('2083566032', (res=>{
                    if (res.ret===0){
                        if (!this.$check.phone(this.phone)) {
                            this.flagPhone = true;
                            return;
                        }
                        if (this.seconds > 0) return;
                        this.$http.get(this.$API.GETCODE, { params: { mobile: this.phone } }).then(res => {
                            console.log(res.data.code);
                            // if (res.data.code === 500 || res.data.code === 0 || res.data.code === 200 || res.data.code === 502) {
                            //if(true){
                            if(res.data.code === 0 ){
                                this.$message({
                                    title: '成功',
                                    message: '验证码发送成功!',
                                    type: 'success'
                                })
                            }
                            //点击验证
                            this.seconds = 59;
                            this.flag = false;
                            this.flag1 = true;
                            let timer = setInterval(() => {
                                //60秒倒计时
                                this.seconds--;
                                if (this.seconds === 0) {
                                    clearInterval(timer);
                                    timer = null;
                                }
                            }, 1000);
                        });
                    }
                }));
                captcha1.show(); // 显示验证码
            },
            updatepaw() {
                if (this.$check.trim(this.code).length === 0) {
                    return
                }
                if (!this.$check.password(this.password)) {
                    return
                }
                if (this.$check.trim(this.confirmPwd) !== this.$check.trim(this.password)) {
                    return
                }
                const param = {
                    verificationCode: this.code,
                    password: this.password,
                    confirmPassword: this.confirmPwd,
                    phoneNumber: this.phone
                }
                // this.$http.get('buyer/update/pwd', {params: param}).then(res => {
                this.$http.post('/buyer/forgetpwd', param).then(res => {
                    if(res.data.code===0) {
                        this.$router.push({name: 'login'})
                        this.phone = ''
                        this.password = ''
                        this.confirmPwd = ''
                        this.code = ''
                        this.seconds = 0
                        this.flag = true
                        this.flag1 = false
                    }
                })
            },
            login() {
                this.$router.push('/login')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/common.scss';

    @mixin icon($m) {
        $url: '../../assets/image/' + $m;
        @include bgImage($url);
        background-size: contain;
    }

    .index {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        /*---以下是头部----*/
        .header1 {
            width: 1200px;
            height: 149px;
            margin: 0 auto;
            > div {
                float: left;
            }
            .header-l {
                width: 404px;
                height: 149px;
                  display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
                img {
                    display: block;
                    width: 100%;
                    height: 58px;
                    // margin-top: 50px;
                }
            }
            .header-c {
                margin-left: 23px;
                p {
                    height: 27px;
                    font-size: 16px;
                    color: #333333;
                }
                .p1 {
                    margin-top: 46px;
                }
            }
            .header-r {
                width: 175px;
                font-size: 17px;
                line-height: 149px;
                float: right;
                text-align: right;
                .header-r1 {
                    color: #d82229;
                    cursor: pointer;
                }
                span {
                    color: #666666;
                }
                a {
                    color: #d82229;
                }
            }
        }
        .main {
            width: 600px;
            height: 600px;
            margin: 0 auto;
            .div1 {
                height: 77px;
                font-size: 18px;
                color: #d82229;
                line-height: 77px;
                width: 100%;
                display: inline-block;
            }
            .div2 {
                width: 100%;
                height: 45px;
                font-size: 18px;
                background: url(../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
                color: #999999;
                margin-bottom: 20px;
                input {
                    padding-left: 50px;
                    width: 284px;
                    height: 43px;
                    border: 0;
                    border: 1px solid #eaeaea;
                    background: none;
                    border-radius: 5px;
                    float: left;
                }
                .tishi {
                    float: left;
                    width: 350px;
                    height: 43px;
                    align-items: center;
                    font-size: 12px;
                    .tishi-l {
                        margin-left: 13px;
                        color: #d82229;
                        float: left;
                        line-height: 46px;
                    }
                    .tishi-r {
                        line-height: 46px;
                        float: left;
                    }
                }
            }

            .mima {
                position: relative;
                background: url(../../assets/icon/mima1.png) no-repeat 13px 13px;
                .p1 {
                    font-size: 12px;
                    position: absolute;
                    bottom: -25px;
                    color: #d82229;
                    width: 300px;
                    background: url(../../assets/icon/tishi.png) no-repeat 10px 0px;
                    padding-left: 35px;
                }
            }
            .div3 {
                width: 100%;
                height: 45px;
                margin-bottom: 20px;
                .div3-1 {
                    width: 350px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    float: left;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    color: #333333;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .div3-2 {
                    width: 424px;
                    height: 45px;
                    float: left;
                    line-height: 45px;
                    text-align: center;
                    font-size: 12px;
                    .div3-2-l {
                        margin-left: 13px;
                        color: #d82229;
                        float: left;
                        line-height: 46px;
                    }
                    .div3-2-r {
                        line-height: 46px;
                        float: left;
                        color: #999999;
                    }
                }
            }
            .div3-y {
                width: 100%;
                height: 45px;
                margin-bottom: 30px;
                .teshu {
                    width: 150px;
                    height: 40px;
                    border-radius: 5px;
                    color: #999999;
                    border: 1px solid #ccc;
                    background: url(../../assets/icon/yanzhengma.png) no-repeat 13px 13px;
                    float: left;
                    padding-left: 54px;
                }
                .time {
                    width: 117px;
                    height: 45px;
                    background: #d82229;
                    float: left;
                    margin-left: 12px;
                    border-radius: 5px;
                    color: #fff;
                    text-align: center;
                    line-height: 45px;
                }
            }
        }
        .div4 {
            height: 18px;
            position: relative;

            input:checked + label {
                // 这段代码是当input选中时,给input的相邻标签label加一个背景图片
                background-image: url('../../assets/icon/gouxuan.png');
                background-repeat: no-repeat;
                background-position: 2px 2px;
            }
            .ipt1 {
                display: none;
                float: left;
                background: url(../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
            }
            .lab1 {
                float: left;
                position: absolute;
                width: 18px;
                height: 18px;
                border: 1px solid #ccc;
                top: -2px;
            }

            .div4-2 {
                float: left;
                margin-left: 16px;
                line-height: 18px;
                .span1 {
                    width: #666666;
                    margin-left: 16px;
                }
                .span2 {
                    margin-left: 7px;
                    color: #d82229;
                }
            }
        }
        .regist {
            width: 337px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            background: #d82229;
            border: none;
            margin-top: 30px;
            font-size: 16px;
            color: #fff;
            border: 0;
            border-radius: 5px;
            outline: none;
            cursor: pointer;
            &.grey {
                background: #ccc;
            }}
        .line {
            width: 100%;
            height: 2px;
            background: #d82229;
        }
        .log {
            padding: 52px 0;
            padding-left: 20%;
        }
        .border {
            border: 1px solid $color168;
        }
    }
</style>


